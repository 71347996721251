export default class ButtonBuilder{
    constructor() {
        this.settings = null;
        this.container = null;
        this.openContainer = null;
        this.clickContainer = null;
        this.messageContainer = null;
        this.closer = null;
        this.interval = 3000; // 3 секунда
        this.index = 0;
        this.linkKeys = [];
    }

    init(settings, btn) {
        this.settings = settings;
        const links = settings.links

        // Кликабельный релативный контейнер
        const clickContainer = document.createElement('div');
        clickContainer.classList.add('widget__clc-container');
        btn.appendChild(clickContainer);
        this.clickContainer = clickContainer;

        // Создаем контейнер для кнопки
        const innerContainer = document.createElement('div');
        innerContainer.classList.add('widget__btn-container');
        clickContainer.appendChild(innerContainer);

        this.container = innerContainer;

        // Создаем открывающийся контейнер
        const openContainer = document.createElement('div');
        openContainer.classList.add('widget__opn-container');
        openContainer.style.opacity = '0';
        clickContainer.appendChild(openContainer);

        // Перебираем объект с сылками, формируем открывающийся контейнер
        const keys = [];
        for (const [key, value] of Object.entries(links)) {
            keys.push(key)
            // Создаем открывающийся контейнер
            const linkContainer = document.createElement('div');
            linkContainer.classList.add('widget__link-container');
            openContainer.appendChild(linkContainer);

            const link = document.createElement('a');
            link.classList.add('widget__link');
            link.classList.add(key);
            link.href = value;

            // Навешиваем атрибуты для целей
            link.setAttribute('data-goal-yandex-click', `JB_${key}_click`)
            link.setAttribute('data-goal-google-click', `JB_${key}_click`)

            // Вешаем листинер, на клик и отправку цели
            link.addEventListener('click', (e) => {
                if (link.dataset.goalYandexClick) {
                    this.proceedYandexGoal(link.dataset.goalYandexClick);
                }
                if (link.dataset.goalGoogleClick) {
                    this.proceedGoogleGoal(`click#${link.dataset.goalGoogleClick}`);
                }
            })

            link.setAttribute('target', '_blank');
            linkContainer.appendChild(link);
        }


        // Добавляем в открывающийся контейнер closer
        const closerContainer = document.createElement('div');
        closerContainer.classList.add('widget__link-container');
        openContainer.appendChild(closerContainer);
        this.openContainer = openContainer;

        const closerLink = document.createElement('a');
        closerLink.classList.add('widget__link');
        closerLink.classList.add('closer');
        closerLink.href = 'javascript:void(0);';
        closerContainer.appendChild(closerLink);
        this.closer = closerLink

        this.bindCloser();

        // Сразу ставим первую иконку
        this.container.classList.add(keys[0]);

        this.linkKeys = keys
        setInterval(
            () => this.changeBg(this),
            this.interval
        )
            if (this.settings.message) {
            setTimeout(
                () => this.bindMessage(),
                this.settings.message.timeout * 1000
            )
        }
    }

    // Меняем бэкграунд у кнопки, перебираем иконки внутри
    changeBg(changer) {
        const keys = changer.linkKeys;

        if (changer.index === 0) {
            changer.container.classList.remove(keys[keys.length - 1]);
        } else {
            changer.container.classList.remove(keys[changer.index - 1]);
        }

        changer.container.classList.add(keys[changer.index]);

        changer.index++;
        if (changer.index >= keys.length) {
            changer.index = 0;
        }
    }

    bindCloser() {
        const closer = this.closer;
        const clickContainer = this.clickContainer;
        clickContainer.addEventListener('click', () => {
            if (this.messageContainer) {
                this.messageContainer.classList.remove('_show');
            }
            clickContainer.classList.add('_open');
        })
        closer.addEventListener('click', (event) => {
            event.stopPropagation();
            clickContainer.classList.remove('_open');
        })
    }

    bindMessage() {
        // Создаем контейнер для сообщения
        const messageContainer = document.createElement('div');
        messageContainer.classList.add('widget__msg-container');
        this.clickContainer.appendChild(messageContainer);
        this.messageContainer = messageContainer;

        // Создаем внутренний элемент
        const messageInner = document.createElement('div');
        messageInner.classList.add('widget__msg-inner');

        // Втыкаем текст и настройки в зависимости от положения виджета
        messageInner.innerText = this.settings.message.text
        if (this.settings.left) {
            messageContainer.classList.add('_left');
            messageInner.classList.add('_left');
        }
        if (this.settings.right) {
            messageContainer.classList.add('_right');
            messageInner.classList.add('_right');
        }
        const size = this.settings.size;
        switch (size) {
            case 'L':
                messageContainer.classList.add('_large');
                break;
            case 'M':
                messageContainer.classList.add('_medium');
                break;
            case 'S':
                messageContainer.classList.add('_small');
                break;
        }

        messageContainer.appendChild(messageInner);
        // Если в момент рендера кнопки открыт контейнер с ссылками на мессенджеры,
        // то ничего не делаем
        if (this.clickContainer.classList.contains('_open')) {
            // doing nothing
        } else {
            // Иначе показываем message
            messageContainer.classList.add('_show');
        }
    }

    proceedGoogleGoal(goalData) {
        if (window.gtag) {
            const goalElements = goalData.split('#');
            if (!goalElements[0] || !goalElements[1]) {
                console.error('GoalManager: Incorrect goal data for google, please read documentation. Format google goal: event_category#action');
            }
            const [eventCategory, action, label, value] = goalElements;
            const options = {
                event_category: eventCategory,
            };
            if (label) {
                options.event_label = label;
            }
            if (value) {
                options.value = value;
            }
            if (window.gtag) {
                window.gtag('event', action, options);
            } else {
                console.error('GoalManager: Try send google event, but google counter not found');
            }
        }
    }

    proceedYandexGoal(goalName) {
        if (window.Ya && window.Ya._metrika) {
            window.Ya._metrika.counter.reachGoal(goalName);
        } else {
            console.error('GoalManager: Try send yandex event, but yandex counter not found');
        }
    }
}
