export default class Widget {
    constructor() {
        this.settings = {};
        this.bind();
    }

    bind() {
        document.addEventListener('DOMContentLoaded', () => {
            if (window.JustButtonSettings) {
                this.settings = window.JustButtonSettings;
                this.init();
            }
        });
    }

    init() {
        const widgetVersion = this.settings.version;

        // Инициализируем шадоу рут контейнер, кнопка со стилями будет лежать в нем,
        // независимо от общих стилей сайта

        // Цепляем пустой контейнер к body
        const body = document.querySelector('body');
        const container = document.createElement('div');
        body.appendChild(container);

        // Открываем шадоу рут
        const shadowRoot = container.attachShadow({ mode: 'open' });

        // Засовываем туда линк на стили
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        // Тут прокинем версию из настроек - ссылка будет вести на скомпиленый css файл конкретной версии
        if (this.settings.local) {
            link.href = `http://127.0.0.1:9000/${widgetVersion}/main.css`
        } else {
            link.href = `https://knopka.rclass.pro/src/${widgetVersion}/main.css`
        }
        shadowRoot.appendChild(link);

        // В шадоу рут суем контейнер для внутренностей
        const innerContainer = document.createElement('div');
        innerContainer.classList.add('widget');
        shadowRoot.appendChild(innerContainer);

        // Внутри контейнера рендерим кнопку
        this.renderBtn(innerContainer);
    }

    renderBtn(innerContainer) {
        const btn = document.createElement('button');

        // В зависимости от пришедших настроек рендерим кнопку с внутряком
        btn.classList.add('widget__btn');

        // Отступы
        if (this.settings.right) {
            btn.style.right = this.settings.right + 'px';
        }
        if (this.settings.left) {
            btn.style.left = this.settings.left + 'px';
        }
        btn.style.bottom = this.settings.bottom + 'px';

        // Устанавливаем размер
        if (this.settings.size) {
            const size = this.settings.size;
            switch (size) {
                case 'L':
                    btn.classList.add('widget__btn_size-l');
                    break;
                case 'M':
                    btn.classList.add('widget__btn_size-m');
                    break;
                case 'S':
                    btn.classList.add('widget__btn_size-s');
                    break;
            }
        }

        // Суем кнопку в контейнер
        innerContainer.appendChild(btn);

        if (this.settings.links) {
            window.BackChanger.init(this.settings, btn)
        }
    }
}